
  
  export const calculateBreakeven = (cost, returnPerHour) => {
    const hours = cost / returnPerHour;
    if (hours >= 24) {
      const days = Math.floor(hours / 24);
      const remainingHours = Math.floor(hours % 24);
      return `${days} day${days !== 1 ? 's' : ''} and ${remainingHours} hour${remainingHours !== 1 ? 's' : ''}`;
    } else if (hours >= 1) {
      const wholeHours = Math.floor(hours);
      const minutes = Math.floor((hours % 1) * 60);
      return `${wholeHours} hour${wholeHours !== 1 ? 's' : ''} and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
      const minutes = Math.floor(hours * 60);
      const seconds = Math.floor(((hours * 60) % 1) * 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} and ${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  };

// utils.js

/**
 * Abbreviates a number to a more readable format
 * @param {number} num - The number to abbreviate
 * @returns {string} The abbreviated number
 */
export function abbreviateNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
    }
    return num.toString();
  }
  

  
  // As funções currentProfitPerHour e currentCost já foram fornecidas anteriormente,
  // mas aqui estão novamente para completude:
  
  /**
   * Calculates the current profit per hour based on the level
   * @param {number} currentLevel - The current level of the investment
   * @param {number} initialProfitPerHour - The initial profit per hour
   * @returns {number} The current profit per hour
   */
  export function currentProfitPerHour(currentLevel, initialProfitPerHour) {
    return Math.round(initialProfitPerHour * Math.pow(1.07, currentLevel));
  }
  
  /**
   * Calculates the current cost based on the level
   * @param {number} currentLevel - The current level of the investment
   * @param {number} initialCost - The initial cost
   * @returns {number} The current cost
   */
  export function currentCost(currentLevel, initialCost) {
    return Math.round(initialCost * Math.pow(1.02469507656536, Math.pow(currentLevel, 2) + 3 * currentLevel));
  }