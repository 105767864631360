const cardsValues = [{ id: 'ceo', name: 'CEO', type: 'PR&Team', initialCost: 1000, initialProfitPerHour: 100, prequesite: null, levelToUnlock: null },
{ id: 'marketing', name: 'Marketing', type: 'PR&Team', initialCost: 1000, initialProfitPerHour: 70, prequesite: null, levelToUnlock: null },
{ id: 'it-team', name: 'IT Team', type: 'PR&Team', initialCost: 2000, initialProfitPerHour: 240, prequesite: null, levelToUnlock: null },
{ id: 'support-team', name: 'Support team', type: 'PR&Team', initialCost: 750, initialProfitPerHour: 70, prequesite: null, levelToUnlock: null },
{ id: 'hamsterbook', name: 'HamsterBook', type: 'PR&Team', initialCost: 500, initialProfitPerHour: 70, prequesite: null, levelToUnlock: null },
{ id: 'hamstertube', name: 'HamsterTube', type: 'PR&Team', initialCost: 600, initialProfitPerHour: 90, prequesite: 'hamsterbook', levelToUnlock: 5 },
{ id: 'x', name: 'X', type: 'PR&Team', initialCost: 550, initialProfitPerHour: 80, prequesite: null, levelToUnlock: null },
{ id: 'cointelegraph', name: 'Cointelegraph', type: 'PR&Team', initialCost: 350, initialProfitPerHour: 40, prequesite: null, levelToUnlock: null },
{ id: 'hamstergram', name: 'HamsterGram', type: 'PR&Team', initialCost: 500, initialProfitPerHour: 50, prequesite: 'hamstertube', levelToUnlock: 8 },
{ id: 'tiktok', name: 'TikTok', type: 'PR&Team', initialCost: 750, initialProfitPerHour: 100, prequesite: 'hamstergram', levelToUnlock: 3 },
{ id: 'coindesk', name: 'Coindesk', type: 'PR&Team', initialCost: 1000, initialProfitPerHour: 80, prequesite: 'cointelegraph', levelToUnlock: 10 },
{ id: 'influencers', name: 'Influencers', type: 'PR&Team', initialCost: 2500, initialProfitPerHour: 270, prequesite: 'x', levelToUnlock: 5 },
{ id: 'partnership-program', name: 'Partnership program', type: 'PR&Team', initialCost: 500, initialProfitPerHour: 70, prequesite: 'influencers', levelToUnlock: 7 },
{ id: 'product-team', name: 'Product team', type: 'PR&Team', initialCost: 1000, initialProfitPerHour: 100, prequesite: 'ceo', levelToUnlock: 2 },
{ id: 'bisdev-team', name: 'BisDev team', type: 'PR&Team', initialCost: 500, initialProfitPerHour: 50, prequesite: null, levelToUnlock: null },
{ id: 'two-factor-authentication', name: 'Two factor authentication', type: 'PR&Team', initialCost: 1000, initialProfitPerHour: 125, prequesite: null, levelToUnlock: null },
{ id: 'ux-and-ui-team', name: 'UX and UI team', type: 'PR&Team', initialCost: 760, initialProfitPerHour: 175, prequesite: 'ceo', levelToUnlock: 5 },
{ id: 'security-team', name: 'Security team', type: 'PR&Team', initialCost: 1000, initialProfitPerHour: 200, prequesite: 'two-factor-authentification', levelToUnlock: 13 },
{ id: 'qa-team', name: 'QA team', type: 'PR&Team', initialCost: 1275, initialProfitPerHour: 190, prequesite: 'it-team', levelToUnlock: 3 },
{ id: 'antihacking-shield', name: 'Antihacking shield', type: 'PR&Team', initialCost: 2000, initialProfitPerHour: 110, prequesite: 'qa-team', levelToUnlock: 5 },
{ id: 'risk-management-team', name: 'Risk management team', type: 'PR&Team', initialCost: 2000, initialProfitPerHour: 265, prequesite: 'ceo', levelToUnlock: 8 },
{ id: 'security-audition', name: 'Security Audition', type: 'PR&Team', initialCost: 3000, initialProfitPerHour: 100, prequesite: null, levelToUnlock: null },
{ id: 'anonymous-transactions-ban', name: 'Anonymous transactions ban', type: 'PR&Team', initialCost: 900, initialProfitPerHour: 300, prequesite: null, levelToUnlock: null },
{ id: 'blocking-suspicious-accounts', name: 'Blocking suspicious accounts', type: 'PR&Team', initialCost: 1250, initialProfitPerHour: 160, prequesite: 'anonymous-transactions-ban', levelToUnlock: 7 },
{ id: 'tokenomics-expert', name: 'Tokenomics expert', type: 'PR&Team', initialCost: 5000, initialProfitPerHour: 500, prequesite: 'bisdev-team', levelToUnlock: 3 },
{ id: 'consensus-explorer-pass', name: 'Consensus Explorer Pass', type: 'PR&Team', initialCost: 25000, initialProfitPerHour: 1500, prequesite: null, levelToUnlock: null },
{ id: 'vc-labs', name: 'VC Labs', type: 'PR&Team', initialCost: 15500, initialProfitPerHour: 500, prequesite: null, levelToUnlock: null },
{ id: 'compliance-officer', name: 'Compliance officer', type: 'PR&Team', initialCost: 3500, initialProfitPerHour: 120, prequesite: null, levelToUnlock: null },
{ id: 'welcome-to-amsterdam', name: 'Welcome to Amsterdam', type: 'PR&Team', initialCost: 3000, initialProfitPerHour: 325, prequesite: 'usdt-on-ton', levelToUnlock: 3 },
{ id: 'development-hub-mumbai', name: 'Development Hub Mumbai', type: 'PR&Team', initialCost: 90000, initialProfitPerHour: 4000, prequesite: 'it-team', levelToUnlock: 15 },
{ id: 'data-center-tokyo', name: 'Data Center Tokyo', type: 'PR&Team', initialCost: 200000, initialProfitPerHour: 6500, prequesite: 'dubai-office', levelToUnlock: 1 },
{ id: 'leaderboards', name: 'Leaderboards', type: 'PR&Team', initialCost: 20000, initialProfitPerHour: 750, prequesite: 'kyc', levelToUnlock: 15 },
{ id: 'fan-tokens', name: 'Fan tokens', type: 'Markets', initialCost: 10000, initialProfitPerHour: 950, prequesite: null, levelToUnlock: null },
{ id: 'staking', name: 'Staking', type: 'Markets', initialCost: 3500, initialProfitPerHour: 600, prequesite: 'kyc', levelToUnlock: 7 },
{ id: 'btc-pairs', name: 'BTC pairs', type: 'Markets', initialCost: 250, initialProfitPerHour: 40, prequesite: null, levelToUnlock: null },
{ id: 'eth-pairs', name: 'ETH pairs', type: 'Markets', initialCost: 300, initialProfitPerHour: 40, prequesite: null, levelToUnlock: null },
{ id: 'top-10-cmc-pairs', name: 'Top 10 cmc pairs', type: 'Markets', initialCost: 1000, initialProfitPerHour: 80, prequesite: null, levelToUnlock: null },
{ id: 'gamefi-tokens', name: 'GameFi tokens', type: 'Markets', initialCost: 500, initialProfitPerHour: 70, prequesite: null, levelToUnlock: null },
{ id: 'defi2.0-tokens', name: 'Defi2.0 tokens', type: 'Markets', initialCost: 500, initialProfitPerHour: 40, prequesite: null, levelToUnlock: null },
{ id: 'socialfi-tokens', name: 'SocialFi tokens', type: 'Markets', initialCost: 500, initialProfitPerHour: 50, prequesite: 'gamefi-tokens', levelToUnlock: 11 },
{ id: 'meme-coins', name: 'Meme coins', type: 'Markets', initialCost: 2000, initialProfitPerHour: 110, prequesite: 'influencers', levelToUnlock: 10 },
{ id: 'shit-coins', name: 'Shit coins', type: 'Markets', initialCost: 5000, initialProfitPerHour: 590, prequesite: 'kyb', levelToUnlock: 3 },
{ id: 'margin-trading-x10', name: 'Margin trading x10', type: 'Markets', initialCost: 2500, initialProfitPerHour: 275, prequesite: 'shit-coins', levelToUnlock: 5 },
{ id: 'margin-trading-x20', name: 'Margin trading x20', type: 'Markets', initialCost: 2500, initialProfitPerHour: 350, prequesite: 'margin-trading-x10', levelToUnlock: 5 },
{ id: 'margin-trading-x30', name: 'Margin trading x30', type: 'Markets', initialCost: 3500, initialProfitPerHour: 500, prequesite: 'legal-opinion', levelToUnlock: 6 },
{ id: 'margin-trading-x50', name: 'Margin trading x50', type: 'Markets', initialCost: 10000, initialProfitPerHour: 1100, prequesite: 'margin-trading-x30', levelToUnlock: 8 },
{ id: 'margin-trading-x75', name: 'Margin trading x75', type: 'Markets', initialCost: 7500, initialProfitPerHour: 1100, prequesite: 'short-squeeze', levelToUnlock: 10 },
{ id: 'margin-trading-x100', name: 'Margin trading x100', type: 'Markets', initialCost: 5000, initialProfitPerHour: 975, prequesite: 'short-squeeze', levelToUnlock: 3 },
{ id: 'derivatives', name: 'Derivatives', type: 'Markets', initialCost: 2500, initialProfitPerHour: 495, prequesite: 'margin-trading-x20', levelToUnlock: 9 },
{ id: 'prediction-markets', name: 'Prediction markets', type: 'Markets', initialCost: 1750, initialProfitPerHour: 345, prequesite: null, levelToUnlock: null },
{ id: 'web3-integration', name: 'Web3 integration', type: 'Markets', initialCost: 6500, initialProfitPerHour: 790, prequesite: 'anti-money-laundering', levelToUnlock: 8 },
{ id: 'dao', name: 'DAO', type: 'Markets', initialCost: 1000, initialProfitPerHour: 230, prequesite: null, levelToUnlock: null },
{ id: 'p2p-trading', name: 'P2P trading', type: 'Markets', initialCost: 4200, initialProfitPerHour: 390, prequesite: 'kyc', levelToUnlock: 7 },
{ id: 'trading-bots', name: 'Trading bots', type: 'Markets', initialCost: 2100, initialProfitPerHour: 195, prequesite: 'it-team', levelToUnlock: 9 },
{ id: 'layerzero-listing', name: 'LayerZero Listing', type: 'Markets', initialCost: 10000, initialProfitPerHour: 900, prequesite: null, levelToUnlock: null },
{ id: 'kyc', name: 'KYC', type: 'Legal', initialCost: 100, initialProfitPerHour: 10, prequesite: null, levelToUnlock: null },
{ id: 'kyb', name: 'KYB', type: 'Legal', initialCost: 500, initialProfitPerHour: 60, prequesite: null, levelToUnlock: null },
{ id: 'legal-opinion', name: 'Legal opinion', type: 'Legal', initialCost: 1000, initialProfitPerHour: 60, prequesite: null, levelToUnlock: null },
{ id: 'sec-transparancy', name: 'SEC transparancy', type: 'Legal', initialCost: 1200, initialProfitPerHour: 60, prequesite: 'legal-opinion', levelToUnlock: 5 },
{ id: 'anti-money-laundering', name: 'Anti money laundering', type: 'Legal', initialCost: 3000, initialProfitPerHour: 280, prequesite: null, levelToUnlock: null },
{ id: 'licence-uae', name: 'Licence UAE', type: 'Legal', initialCost: 5000, initialProfitPerHour: 560, prequesite: 'kyc', levelToUnlock: 5 },
{ id: 'licence-europe', name: 'Licence Europe', type: 'Legal', initialCost: 5000, initialProfitPerHour: 720, prequesite: 'kyc', levelToUnlock: 5 },
{ id: 'licence-asia', name: 'Licence Asia', type: 'Legal', initialCost: 5000, initialProfitPerHour: 370, prequesite: 'kyc', levelToUnlock: 5 },
{ id: 'licence-south-america', name: 'Licence South America', type: 'Legal', initialCost: 5000, initialProfitPerHour: 390, prequesite: 'kyc', levelToUnlock: 5 },
{ id: 'licence-australia', name: 'Licence Australia', type: 'Legal', initialCost: 5000, initialProfitPerHour: 680, prequesite: 'kyc', levelToUnlock: 5 },
{ id: 'licence-north-america', name: 'Licence North America', type: 'Legal', initialCost: 10000, initialProfitPerHour: 960, prequesite: 'sec-transparancy', levelToUnlock: 10 },
{ id: 'licence-nigeria', name: 'Licence Nigeria', type: 'Legal', initialCost: 1500, initialProfitPerHour: 170, prequesite: null, levelToUnlock: null },
{ id: 'licence-japan', name: 'Licence Japan', type: 'Legal', initialCost: 50000, initialProfitPerHour: 2600, prequesite: null, levelToUnlock: null },
{ id: 'licence-ethiopia', name: 'Licence Ethiopia', type: 'Legal', initialCost: 35000, initialProfitPerHour: 1800, prequesite: null, levelToUnlock: null },
{ id: 'licence-india', name: 'Licence India', type: 'Legal', initialCost: 45000, initialProfitPerHour: 2500, prequesite: null, levelToUnlock: null },
{ id: 'licence-bangladesh', name: 'Licence Bangladesh', type: 'Legal', initialCost: 70000, initialProfitPerHour: 3500, prequesite: null, levelToUnlock: null },
{ id: 'licence-indonesia', name: 'Licence Indonesia', type: 'Legal', initialCost: 100000, initialProfitPerHour: 5000, prequesite: null, levelToUnlock: null },
{ id: 'licence-vietnam', name: 'Licence Vietnam', type: 'Legal', initialCost: 80000, initialProfitPerHour: 4000, prequesite: null, levelToUnlock: null },
{ id: 'licence-turkey', name: 'Licence Turkey', type: 'Legal', initialCost: 75000, initialProfitPerHour: 3000, prequesite: null, levelToUnlock: null },
{ id: 'licence-philippines', name: 'Licence Philippines', type: 'Legal', initialCost: 150000, initialProfitPerHour: 6500, prequesite: 'top-10-global-ranking', levelToUnlock: 3 },
{ id: 'sports-integration', name: 'Sports integration', type: 'Specials', initialCost: 35000, initialProfitPerHour: 3000, prequesite: null, levelToUnlock: null },
{ id: 'hamsters-break-records', name: 'Hamsters break records', type: 'Specials', initialCost: 500000, initialProfitPerHour: 2750, prequesite: null, levelToUnlock: null },
{ id: 'web3-game-con', name: 'Web3 Game Con', type: 'Specials', initialCost: 30000, initialProfitPerHour: 1200, prequesite: null, levelToUnlock: null },
{ id: 'x-network-10-million', name: 'X Network 10 Million', type: 'Specials', initialCost: 7000, initialProfitPerHour: 400, prequesite: null, levelToUnlock: null },
{ id: 'hamster-green-energy', name: 'Hamster Green energy', type: 'Specials', initialCost: 17000, initialProfitPerHour: 2000, prequesite: null, levelToUnlock: null },
{ id: 'youtube-25-million', name: 'YouTube 25 Million', type: 'Specials', initialCost: 18000, initialProfitPerHour: 2100, prequesite: null, levelToUnlock: null },
{ id: 'tg-leaders', name: 'TG Leaders', type: 'Specials', initialCost: 22000, initialProfitPerHour: 2300, prequesite: null, levelToUnlock: null },
{ id: 'premarket-launch', name: 'Premarket Launch', type: 'Specials', initialCost: 1000000, initialProfitPerHour: 7500, prequesite: null, levelToUnlock: null },
{ id: 'hamster-kombat-merch', name: 'Hamster Kombat merch', type: 'Specials', initialCost: 3000, initialProfitPerHour: 100, prequesite: null, levelToUnlock: null },
{ id: 'ton-+-hamster-kombat-=-success', name: 'TON + Hamster Kombat = Success', type: 'Specials', initialCost: 1000000, initialProfitPerHour: 5000, prequesite: null, levelToUnlock: null },
{ id: 'consensus-piranha-pass', name: 'Consensus Piranha Pass', type: 'Specials', initialCost: 50000, initialProfitPerHour: 1900, prequesite: null, levelToUnlock: null },
{ id: 'lambo-for-a-hamster-ceo', name: 'Lambo for a Hamster CEO', type: 'Specials', initialCost: 1000000, initialProfitPerHour: 0, prequesite: null, levelToUnlock: null },
{ id: 'web3-academy-launch', name: 'Web3 academy launch', type: 'Specials', initialCost: 11000, initialProfitPerHour: 1500, prequesite: null, levelToUnlock: null },
{ id: 'youtube-gold-button', name: 'YouTube Gold Button', type: 'Specials', initialCost: 2500, initialProfitPerHour: 275, prequesite: null, levelToUnlock: null },
{ id: 'hamster-youtube-channel', name: 'Hamster YouTube Channel', type: 'Specials', initialCost: 1500, initialProfitPerHour: 250, prequesite: null, levelToUnlock: null },
{ id: 'bitcoin-pizza-day', name: 'Bitcoin Pizza Day', type: 'Specials', initialCost: 1000, initialProfitPerHour: 100, prequesite: null, levelToUnlock: null },
{ id: 'top-10-global-ranking', name: 'Top 10 Global Ranking', type: 'Specials', initialCost: 10000, initialProfitPerHour: 1200, prequesite: null, levelToUnlock: null },
{ id: 'nft-collection-launch', name: 'NFT Collection Launch', type: 'Specials', initialCost: 17000, initialProfitPerHour: 1200, prequesite: 'margin-trading-x100', levelToUnlock: 10 },
{ id: 'special-hamster-conference', name: 'Special Hamster Conference', type: 'Specials', initialCost: 5000, initialProfitPerHour: 900, prequesite: null, levelToUnlock: null },
{ id: 'joe-roogan-podcast', name: 'Joe Roogan Podcast', type: 'Specials', initialCost: 1000000, initialProfitPerHour: 0, prequesite: null, levelToUnlock: null },
{ id: 'dubai-office', name: 'Dubai Office', type: 'Specials', initialCost: 1000000, initialProfitPerHour: 0, prequesite: 'licence-uae', levelToUnlock: 15 },
{ id: 'short-squeeze', name: 'Short squeeze', type: 'Specials', initialCost: 834397, initialProfitPerHour: 1970, prequesite: null, levelToUnlock: null },
{ id: 'there-are-two-chairs...', name: 'There are two chairs...', type: 'Specials', initialCost: 50000, initialProfitPerHour: 2000, prequesite: null, levelToUnlock: null },
{ id: 'long-squeeze', name: 'Long squeeze', type: 'Specials', initialCost: 30000, initialProfitPerHour: 2000, prequesite: null, levelToUnlock: null },
{ id: 'villa-for-the-dev-team', name: 'Villa for the DEV team', type: 'Specials', initialCost: 2000, initialProfitPerHour: 450, prequesite: null, levelToUnlock: null },
{ id: 'apps-center-listing', name: 'Apps Center Listing', type: 'Specials', initialCost: 15000, initialProfitPerHour: 1000, prequesite: null, levelToUnlock: null },
{ id: 'bogdanoff-is-calling', name: 'Bogdanoff is calling', type: 'Specials', initialCost: 5000, initialProfitPerHour: 475, prequesite: 'margin-trading-x50', levelToUnlock: 15 },
{ id: 'usdt-on-ton', name: 'USDT on TON', type: 'Specials', initialCost: 26530, initialProfitPerHour: 1890, prequesite: null, levelToUnlock: null },
{ id: 'cx-hub-istanbul', name: 'CX Hub Istanbul', type: 'Specials', initialCost: 55000, initialProfitPerHour: 3000, prequesite: null, levelToUnlock: null },
{ id: 'riyadh-masters-2024', name: 'Riyadh Masters 2024', type: 'Specials', initialCost: 35000, initialProfitPerHour: 3000, prequesite: null, levelToUnlock: null },
{ id: 'quaterfinals-are-coming', name: 'Quaterfinals are coming', type: 'Specials', initialCost: 35000, initialProfitPerHour: 3000, prequesite: null, levelToUnlock: null },
];

export default cardsValues;