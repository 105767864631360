import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { PlusCircleIcon, SearchIcon, XIcon, Menu } from 'lucide-react';
import Card from './Card';
import cardsValues from './cardsValues';
import { currentProfitPerHour, currentCost } from './utils';

const InvestmentGameTool = () => {
  const [cards, setCards] = useState(cardsValues);
  const [customCards, setCustomCards] = useState([]);
  const [newCard, setNewCard] = useState({ name: '', type: '', initialCost: '', initialProfitPerHour: '', prequesite: '', levelToUnlock: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddCardForm, setShowAddCardForm] = useState(false);
  const [debugMessage, setDebugMessage] = useState('');
  const [currentCardLevelMap, setCurrentCardLevelMap] = useState({});
  const [showDebug, setShowDebug] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const isCloudStorageAvailable = () => {
    return false; // Assumindo que CloudStorage não está disponível
  };

  const getStorageData = useCallback(async (key) => {
    const data = localStorage.getItem(key);
    setDebugMessage(`Data retrieved from localStorage: ${data}`);
    return data;
  }, []);

  const setStorageData = useCallback(async (key, value) => {
    localStorage.setItem(key, value);
    setDebugMessage(`Data saved to localStorage: ${value}`);
    return true;
  }, []);

  useEffect(() => {
    const tg = window.Telegram?.WebApp;
    if (tg) {
      tg.ready();
      tg.expand();
    }

    const loadUserData = async () => {
      try {
        const userData = await getStorageData('hamsterCalculatorData');
        if (userData) {
          try {
            const parsedData = JSON.parse(userData);
            setDebugMessage(`Parsed data: ${JSON.stringify(parsedData)}`);
            if (parsedData.customCards) {
              setCustomCards(parsedData.customCards);
            }
            if (parsedData.currentCardLevelMap) {
              setCurrentCardLevelMap(parsedData.currentCardLevelMap);
            }
          } catch (parseError) {
            setDebugMessage(`Error parsing user data: ${parseError.message}`);
          }
        } else {
          setDebugMessage('No saved data found');
        }
      } catch (error) {
        setDebugMessage(`Error loading user data: ${error.message}`);
      }
    };

    loadUserData();
  }, [getStorageData]);

  const saveUserData = useCallback(async (updatedCurrentCardLevelMap, updatedCustomCards) => {
    const dataToSave = {
      customCards: updatedCustomCards,
      currentCardLevelMap: updatedCurrentCardLevelMap,
    };

    try {
      const jsonString = JSON.stringify(dataToSave);
      setDebugMessage(`Attempting to save data: ${jsonString}`);
      const saved = await setStorageData('hamsterCalculatorData', jsonString);

      if (saved) {
        setDebugMessage(prevMessage => prevMessage + "\n" + `Data saved successfully in localStorage`);
      } else {
        setDebugMessage(`Failed to save data`);
      }
    } catch (error) {
      setDebugMessage(`Error saving user data: ${error.message}`);
    }
  }, [setStorageData]);

  const updateCardLevel = useCallback((id, increment, isCustom = false) => {
    setCurrentCardLevelMap(prev => {
      const currentLevel = Math.max(0, (prev[id] || 0) + increment);
      const updatedMap = { ...prev, [id]: currentLevel };
      saveUserData(updatedMap, customCards);
      return updatedMap;
    });
  }, [customCards, saveUserData]);

  const addCustomCard = useCallback(() => {
    if (newCard.name && newCard.type && newCard.initialCost && newCard.initialProfitPerHour) {
      const newCustomCard = {
        id: Date.now().toString(),
        name: newCard.name,
        type: newCard.type,
        initialCost: Number(newCard.initialCost),
        initialProfitPerHour: Number(newCard.initialProfitPerHour),
        prequesite: newCard.prequesite || null,
        levelToUnlock: newCard.levelToUnlock ? Number(newCard.levelToUnlock) : null,
      };
      setCustomCards(prev => {
        const updated = [...prev, newCustomCard];
        saveUserData(currentCardLevelMap, updated);
        return updated;
      });
      setNewCard({ name: '', type: '', initialCost: '', initialProfitPerHour: '', prequesite: '', levelToUnlock: '' });
      setShowAddCardForm(false);
    } else {
      setDebugMessage("Please fill all required fields for the new card.");
    }
  }, [newCard, currentCardLevelMap, saveUserData]);

  const deleteCustomCard = useCallback((id) => {
    setCustomCards(prev => {
      const updated = prev.filter(card => card.id !== id);
      saveUserData(currentCardLevelMap, updated);
      return updated;
    });
  }, [currentCardLevelMap, saveUserData]);

  const allCards = useMemo(() => [...cards, ...customCards], [cards, customCards]);

  const isUnlocked = useCallback((card) => {
    if (!card.prequesite) return true;
    const prerequisiteCard = allCards.find(c => c.id === card.prequesite);
    if (!prerequisiteCard) {
      console.warn(`Dependent card not found for ${card.name}. Considering as unlocked.`);
      return true;
    }
    return (currentCardLevelMap[prerequisiteCard.id] || 0) >= (card.levelToUnlock || 0);
  }, [allCards, currentCardLevelMap]);

  const getCardFromId = useCallback((id) => allCards.find(card => card.id === id), [allCards]);

  const calculateRatio = useCallback((card, level) => {
    const cost = currentCost(level, card.initialCost);
    const profit = currentProfitPerHour(level, card.initialProfitPerHour);
    return profit > 0 ? cost / profit : Infinity;
  }, []);

  const getBestInvestments = useCallback(() => {
    let bestPECard = null;
    let bestPERatio = Infinity;

    for (const card of allCards) {
      if (isUnlocked(card)) {
        const cardRatio = calculateRatio(card, currentCardLevelMap[card.id] || 0);
        if (cardRatio < bestPERatio) {
          bestPERatio = cardRatio;
          bestPECard = card;
        }
      }
    }

    let bestStrategicCard = bestPECard;
    let bestStrategicRatio = bestPERatio;

    for (const card of allCards) {
      if (!isUnlocked(card)) {
        const prequesiteCard = getCardFromId(card.prequesite);
        if (!prequesiteCard) {
          console.warn(`Dependent card not found for ${card.name}. Skipping.`);
          continue;
        }

        let totalCost = 0;
        let totalProfit = 0;

        for (let j = currentCardLevelMap[prequesiteCard.id] || 0; j < card.levelToUnlock; j++) {
          totalCost += currentCost(j, prequesiteCard.initialCost);
          totalProfit += currentProfitPerHour(j, prequesiteCard.initialProfitPerHour);
        }

        let k = 0;
        let cardRatio;
        let previousCardRatio = Infinity;

        do {
          previousCardRatio = cardRatio || Infinity;
          let levelCost = currentCost(k, card.initialCost);
          let levelProfit = currentProfitPerHour(k, card.initialProfitPerHour);
          totalCost += levelCost;
          totalProfit += levelProfit;
          cardRatio = totalCost / totalProfit;
          k++;
        } while (cardRatio < previousCardRatio && k < 30);

        cardRatio = previousCardRatio;

        if (cardRatio < bestStrategicRatio) {
          bestStrategicRatio = cardRatio;
          bestStrategicCard = {
            ...card,
            isStrategic: true,
            prequesiteCard: prequesiteCard
          };
        }
      }
    }

    if (bestStrategicRatio >= bestPERatio) {
      bestStrategicCard = bestPECard;
      bestStrategicRatio = bestPERatio;
    }

    return [
      {
        ...bestPECard,
        currentLevel: currentCardLevelMap[bestPECard.id] || 0,
        pe: (bestStrategicRatio / bestPERatio) * 100,
        type: 'Best Simple Move'
      },
      {
        ...(bestStrategicCard.isStrategic ? bestStrategicCard.prequesiteCard : bestStrategicCard),
        currentLevel: currentCardLevelMap[bestStrategicCard.isStrategic ? bestStrategicCard.prequesiteCard.id : bestStrategicCard.id] || 0,
        strategicRatio: (bestPERatio / bestStrategicRatio) * 100,
        type: 'Best Strategic Move',
        targetCard: bestStrategicCard.isStrategic ? bestStrategicCard : null
      }
    ];
  }, [allCards, currentCardLevelMap, calculateRatio, getCardFromId, isUnlocked]);

  const bestInvestments = useMemo(() => getBestInvestments(), [getBestInvestments, currentCardLevelMap]);

  const resetAllData = useCallback(async () => {
    setCustomCards([]);
    setCurrentCardLevelMap({});
    await setStorageData('hamsterCalculatorData', '');
    setDebugMessage('All user data has been reset');
  }, [setStorageData]);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  return (
    <div className="p-4 max-w-4xl mx-auto text-white min-h-screen bg-gray-900" onClick={closeMenu}>
      <div className="bg-gray-900 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-yellow-400">Hamster Calculator</h1>
          <button onClick={(e) => { e.stopPropagation(); setShowMenu(!showMenu); }} className="text-yellow-400">
            <Menu size={24} />
          </button>
        </div>

        {showMenu && (
          <div className="bg-gray-800 p-4 rounded-lg mb-4" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={() => resetAllData() & closeMenu()}
              className="bg-red-600 hover:bg-red-700 text-white p-2 rounded w-full"
            >
              Reset
            </button>
          </div>
        )}

        {showDebug && debugMessage && (
          <div className="mb-4 p-2 bg-gray-800 text-xs text-yellow-400 rounded overflow-auto max-h-40">
            <h3 className="font-bold mb-1">Debug Log:</h3>
            <pre className="whitespace-pre-wrap">{debugMessage}</pre>
          </div>
        )}

        <div className="mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {bestInvestments.map((card, index) => (
              <div key={index} className="bg-gray-700 p-4 rounded-lg">
                <h3 className="text-lg font-bold text-gray-100 mb-2">{card.type}</h3>
                <Card
                  card={card}
                  updateCardLevel={updateCardLevel}
                  isCustom={customCards.some(c => c.id === card.id)}
                  currentLevel={card.currentLevel}
                  deleteCustomCard={deleteCustomCard}
                />
                {card.pe && (
                  <p className="mt-2 text-sm">Score: <span className="text-green-500 font-semibold">{card.pe.toFixed(2)}</span></p>
                )}
                {card.strategicRatio && (
                  <p className="mt-2 text-sm">Score: <span className="text-green-500 font-semibold">{card.strategicRatio.toFixed(2)}</span></p>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl font-semibold text-yellow-400">All Cards</h2>
            <button
              onClick={() => setShowAddCardForm(!showAddCardForm)}
              className="bg-yellow-600 hover:bg-yellow-700 text-white p-2 rounded"
            >
              {showAddCardForm ? <XIcon size={24} /> : <PlusCircleIcon size={24} />}
            </button>
          </div>

          {showAddCardForm && (
            <div className="bg-gray-800 p-4 rounded-lg mb-4">
              <h3 className="text-lg font-semibold mb-2 text-yellow-400">Add new card</h3>
              <input
                type="text"
                placeholder="Card Name"
                value={newCard.name}
                onChange={(e) => setNewCard({ ...newCard, name: e.target.value })}
                className="border p-2 rounded bg-gray-700 text-white mb-2 w-full"
              />
              <input
                type="text"
                placeholder="Card Type"
                value={newCard.type}
                onChange={(e) => setNewCard({ ...newCard, type: e.target.value })}
                className="border p-2 rounded bg-gray-700 text-white mb-2 w-full"
              />
              <input
                type="number"
                placeholder="Initial Cost"
                value={newCard.initialCost}
                onChange={(e) => setNewCard({ ...newCard, initialCost: e.target.value })}
                className="border p-2 rounded bg-gray-700 text-white mb-2 w-full"
              />
              <input
                type="number"
                placeholder="Initial Profit Per Hour"
                value={newCard.initialProfitPerHour}
                onChange={(e) => setNewCard({ ...newCard, initialProfitPerHour: e.target.value })}
                className="border p-2 rounded bg-gray-700 text-white mb-2 w-full"
              />

                <button
                  onClick={addCustomCard}
                  className="bg-yellow-600 hover:bg-yellow-700 text-white p-2 rounded w-full"
                >
                  Add new card
                </button>
              </div>
            )}
  
            <div className="mb-4 flex items-center bg-gray-800 rounded-lg p-2">
              <SearchIcon size={20} className="mr-2 text-yellow-400" />
              <input
                type="text"
                placeholder="Search cards..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="bg-transparent w-full focus:outline-none text-white"
              />
            </div>
  
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {allCards.filter(card =>
                card.name.toLowerCase().includes(searchTerm.toLowerCase())
              ).map(card => (
                <Card
                  key={card.id}
                  card={card}
                  updateCardLevel={updateCardLevel}
                  isCustom={customCards.some(c => c.id === card.id)}
                  currentLevel={currentCardLevelMap[card.id] || 0}
                  deleteCustomCard={deleteCustomCard}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default InvestmentGameTool;