import React, { useState, useEffect, useRef } from 'react';
import { PlusIcon, MinusIcon, Coins, Settings } from 'lucide-react';
import { abbreviateNumber, calculateBreakeven, currentProfitPerHour, currentCost } from './utils';

const Card = ({ card, updateCardLevel, isCustom, currentLevel, deleteCustomCard }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [editLevel, setEditLevel] = useState(false);
    const [newLevel, setNewLevel] = useState(currentLevel);
    const menuRef = useRef(null);

    const cost = currentCost(currentLevel, card.initialCost);
    const profitPerHour = currentProfitPerHour(currentLevel, card.initialProfitPerHour);
    const ppr = ((profitPerHour / cost) * 100).toFixed(2);

    const handleLevelChange = () => {
        updateCardLevel(card.id, newLevel - currentLevel, isCustom);
        setEditLevel(false);
        setShowMenu(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="bg-gray-800 p-4 rounded-lg flex flex-col w-full relative">
            <div className="absolute top-5 right-4 flex items-center">
                {isCustom && <span className="text-yellow-400 mr-2 text-xs">custom</span>}
                <button onClick={() => setShowMenu(!showMenu)} className="text-yellow-400">
                    <Settings size={18} />
                </button>
                {showMenu && (
                    <div ref={menuRef} className="absolute top-6 right-0 bg-gray-700 p-2 rounded shadow-lg">
                        {isCustom && (
                            <button
                                onClick={() => {deleteCustomCard(card.id); setShowMenu(false);}}
                                className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 rounded text-xs mb-2 w-full"
                            >
                                Delete
                            </button>
                        )}
                        <button
                            onClick={() => {setEditLevel(true); setShowMenu(false);}}
                            className="bg-yellow-600 hover:bg-yellow-700 text-white px-2 py-1 rounded text-xs w-full whitespace-nowrap"
                        >
                            Set Level
                        </button>
                    </div>
                )}
            </div>
            <div className="flex items-center mb-4">
                <div>
                    <h3 className="font-bold text-yellow-400">{card.name}</h3>
                    <p className="text-sm text-gray-400">lvl {currentLevel}</p>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
                <div className="space-y-1">
                    <p className="text-sm text-gray-400">Profit Per Hour:</p>
                    <p className="text-sm text-white flex items-center">
                        <Coins size={16} className="text-yellow-400 mr-1" />
                        <span className="font-semibold">
                            {abbreviateNumber(profitPerHour)}
                        </span>
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-sm text-gray-400">Cost:</p>
                    <p className="text-sm text-white flex items-center">
                        <Coins size={16} className="text-yellow-400 mr-1" />
                        <span className="font-semibold">
                            {abbreviateNumber(cost)}
                        </span>
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-sm text-gray-400">Breakeven:</p>
                    <p className="text-sm text-white">
                        <span className="font-semibold">{calculateBreakeven(cost, profitPerHour)}</span>
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-sm text-gray-400">Profit Per Cost:</p>
                    <p className="text-sm text-white">
                        <span className="font-semibold">{ppr}%</span>
                    </p>
                </div>
            </div>
            {editLevel ? (
                <div className="mt-4">
                    <input
                        type="number"
                        value={newLevel}
                        onChange={(e) => setNewLevel(Math.max(0, Math.min(30, parseInt(e.target.value) || 0)))}
                        className="bg-gray-700 text-white p-2 rounded w-full mb-2"
                    />
                    <button
                        onClick={handleLevelChange}
                        className="bg-yellow-600 hover:bg-yellow-700 text-white p-2 rounded w-full"
                    >
                        Set Level
                    </button>
                </div>
            ) : (
                <div className="flex justify-between mt-6">
                    <button
                        onClick={() => updateCardLevel(card.id, -1, isCustom)}
                        className={`${currentLevel === 0 ? 'bg-gray-700 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'} text-white p-2 rounded flex-1 mr-2`}
                        disabled={currentLevel === 0}
                    >
                        <MinusIcon size={16} className="mx-auto" />
                    </button>
                    <button
                        onClick={() => updateCardLevel(card.id, 1, isCustom)}
                        className={`${currentLevel === 30 ? 'bg-gray-700 cursor-not-allowed' : 'bg-yellow-600 hover:bg-yellow-700'} text-white p-2 rounded flex-1 ml-2`}
                        disabled={currentLevel === 30}
                    >
                        <PlusIcon size={16} className="mx-auto" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Card;