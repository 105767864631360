import React from 'react';
import InvestmentGameTool from './components/InvestmentGameTool';

function App() {
  return (
    <div className="App">
      <InvestmentGameTool />
    </div>
  );
}

export default App;